import { DateTime } from "luxon";
import { useLocale } from "~/composables/useInternationalization";

const useCurrentDateTime = () =>
  useState("currentDateTime", () =>
    DateTime.local().setLocale(useLocale().value)
  );

export const useTimeString = () =>
  useState("timeString", () =>
    useCurrentDateTime().value.toLocaleString(DateTime.TIME_SIMPLE)
  );
export const useDateString = () =>
  useState("dateString", () => {
    const currentDateTime = useCurrentDateTime().value;

    // Customize the formatting options to display the date in "01.Marz 2024" format
    const dateString = currentDateTime.toFormat("dd.LLLL yyyy", {
      locale: useLocale().value,
    });

    return dateString;
  });
export const useCurrentWeekdayString = () =>
  useState("weekdayString", () => useCurrentDateTime().value.weekdayLong);

const updateTime = () => {
  useCurrentDateTime().value = DateTime.local().setLocale(useLocale().value);

  useTimeString().value = useCurrentDateTime().value.toLocaleString({
    ...DateTime.TIME_SIMPLE,
    hour12: false,
  });
  useDateString().value = useCurrentDateTime().value.toLocaleString(
    DateTime.DATE_MED
  );
  useCurrentWeekdayString().value = useCurrentDateTime().value.weekdayLong;
};

const EVERY_15_SECONDS = 15_000;

updateTime();
setInterval(updateTime, EVERY_15_SECONDS);
